import React, { useState } from 'react';
import styled from 'styled-components';

export default function Dextools({ onClose }) {
  window.open(
    'https://www.dextools.io/app/en/ether/pair-explorer/0x4ebdf501199410fc6caa1fa8c77da9aad46594ce',
    '_blank',
    'noreferrer',
  );
  onClose();
}
