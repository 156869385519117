import React, { useEffect, useState } from 'react';

import WinXP from 'WinXP';
import { useGA } from 'hooks';

//Connect Wallet
import { ethers, BigNumber } from 'ethers';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import {
  configureChains,
  createConfig,
  WagmiConfig,
  useWalletClient,
} from 'wagmi';
import { getEthersSigner } from './WinXP/Web3Connection/Signer';
import { getEthersProvider } from './WinXP/Web3Connection/Provider';
import {
  watchWalletClient,
  watchNetwork,
  watchAccount,
  watchPublicClient,
  readContract,
  watchWebSocketPublicClient,
  switchNetwork,
} from '@wagmi/core';
import { mainnet, base } from 'wagmi/chains';

const App = () => {
  useGA('UA-135148027-3', 'winXP');

  //Connection State
  const [ready, setReady] = useState(false);
  const [publicClient, setPubliClient] = useState(null);
  const [signer, setSigner] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [isWrongChain, setIsWrongChain] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [avatarColor, setAvatarColor] = useState('#fff');
  /****/

  useEffect(() => {
    setReady(true);
    const clientChangeEth = watchWalletClient(
      {
        chainId: mainnet.id,
      },
      walletClient => {
        console.log('Listen to Signer');
        if (walletClient) {
          console.log('New Signer');
          getEthersSigner(mainnet).then(sig => {
            setSigner(sig);
          });
        } else {
          setSigner(null);
        }
      },
    );
    const clientChangeBase = watchWalletClient(
      {
        chainId: base.id,
      },
      walletClient => {
        console.log('Listen to Signer');
        if (walletClient) {
          console.log('New Signer');
          getEthersSigner(base).then(sig => {
            setSigner(sig);
          });
        } else {
          setSigner(null);
        }
      },
    );
    const networkChange = watchNetwork(network => {
      console.log('Listen to Network');
      if (network.chain) {
        const currentNetworkId = network.chain.id;
        const isCorrectChain = network.chains.find(
          i => Number(i.id) === Number(currentNetworkId),
        );
        if (!isCorrectChain) {
          setIsWrongChain(true);
        } else {
          setIsWrongChain(false);
        }
      }
    });
    const accountChange = watchAccount(account => {
      if (account.isConnected) {
        setCurrentAccount(account.address);
        setIsConnected(true);
      } else {
        setCurrentAccount(null);
        setIsConnected(false);
      }
    });
    const getNewColor = () => {
      setAvatarColor(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
    };
    getNewColor();
  }, []);
  return (
    <WinXP
      ready={ready}
      publicClient={publicClient}
      signer={signer}
      currentAccount={currentAccount}
      isWrongChain={isWrongChain}
      isConnected={isConnected}
      avatarColor={avatarColor}
    />
  );
};

export default App;
