import React, { useEffect, useState } from "react";
import "./coins.css";
import { ethers, BigNumber } from "ethers";
import ConnectButton from "../../Web3Connection/CustomButton";

//ABIS
import qwnAbi from "../../abis/Qwn.json";
import sqwnAbi from "../../abis/Sqwn.json";
import stakingAbi from "../../abis/NonRebaseStaking.json";
import lpAbi from "../../abis/Lp.json";
import treasuryAbi from "../../abis/Treasury.json";
import nonRebaseCoinAbi from "../../abis/NonRebaseCoin.json";

import dextoolsLarge from "assets/windowsIcons/dextools32.png";

//react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//TOKENS LAUNCHED
const voldemortAddress =
  "0xf8C252D2d9CC62cAd16ef0A69687f6AE6bFd2617".toLocaleLowerCase(); //Non Rebase
const voldermortStakingAddress =
  "0x26a34239aFD67ce79fa2Cc2EB1163417B2D111b4".toLocaleLowerCase();
const voldermortTreasuryAddress =
  "0xfa1c2250bD957c4a2bEC252937Fb520127F772Ab".toLocaleLowerCase();
const voldermortLpAddress =
  "0x03Ac42e54C7bB93c52b933b78f55F7c494E8822D".toLocaleLowerCase();

//fixed amount of decimals without rounding it
const toFixedNoRoundParse = (number, precision = 4) => {
  number = ethers.utils.formatUnits(number, 9);
  number = number.split(".");
  const result = `${number[0]}.${number[1].substring(0, 4)}`;
  if (result === "0.00") {
    return "0.0";
  }
  return Number(result).toLocaleString("en-US");
};

const toFixedNoRoundParseEther = (number, precision = 4) => {
  number = ethers.utils.formatUnits(number, 18);
  number = number.split(".");
  const result = `${number[0]}.${number[1].substring(0, 4)}`;
  if (result === "0.00") {
    return "0.0";
  }
  return Number(result).toLocaleString("en-US");
};

const toFixedNoRoundParseUnit = (number, unit, precision = 4) => {
  number = ethers.utils.formatUnits(number, unit);
  number = number.split(".");
  const result = `${number[0]}.${number[1].substring(0, precision)}`;
  if (result === "0.00") {
    return "0.0";
  }
  if (number[0] === "0") {
    return result.toLocaleString("en-US");
  }
  return Number(result).toLocaleString("en-US");
};

const displayTimeTwoDigits = (number) => {
  number = number.toString();
  if (number.length < 2) {
    return "0" + number;
  }
  return number;
};

const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24)) * 24;
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  return { h: d + h, m, s };
};

function secondsUntilTimestamp(targetTimestampInSeconds) {
  const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

  if (isNaN(targetTimestampInSeconds)) {
    throw new Error("Invalid timestamp");
  }

  const secondsRemaining = Math.max(
    0,
    targetTimestampInSeconds - currentTimestampInSeconds
  );
  return secondsRemaining;
}

export default function Coins({
  onClose,
  signer,
  isWrongChain,
  addressEthBalance,
  ethPrice,
}) {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [tokenPriceInEth, setTokenPriceInEth] = useState(null);
  const [tokenPriceInUsd, setTokenPriceInUsd] = useState(null);
  const [lpPriceInUsd, setLpPriceInUsd] = useState(null);
  const [totalEthLiquidity, setTotalEthLiquidity] = useState(BigNumber.from(0));
  const [lpTotalSupply, setLpTotalSupply] = useState(BigNumber.from(0));
  const [totalSupply, setTotalSupply] = useState({ balance: null });
  const [balanceOf, setBalanceOf] = useState({ balance: BigNumber.from(0) });
  const [allowance, setAllowance] = useState({
    balance: BigNumber.from(0),
  });
  const [wrapperAllowance, setWrapperAllowance] = useState({
    balance: BigNumber.from(0),
  });
  const [wrapperCirculatingSupply, setWrapperCirculatingSupply] = useState({
    balance: BigNumber.from(0),
  });
  const [userInfoWrapper, setUserInfoWrapper] = useState({ amount: null });
  const [totalStaked, setTotalStaked] = useState({
    balance: null,
  });
  const [stakeAmount, setStakeAmount] = useState(BigNumber.from(0));
  const [stakeMinAmount, setStakeMinAmount] = useState(BigNumber.from(0));

  const [stakedAmount, setStakedAmount] = useState({
    balance: BigNumber.from(0),
  });
  const [unclaimedRewards, setUnclaimedRewards] = useState(BigNumber.from(0));
  const [totalToDistribute, setTotalToDistribute] = useState(BigNumber.from(0));
  const [marketCap, setMarketCap] = useState(BigNumber.from(0));
  const [totalUsdLiquidity, setTotalUsdLiquidity] = useState(BigNumber.from(0));
  const [epochDistribute, setEpochDistribute] = useState(BigNumber.from(0));
  // const [wrapperIndex, setWrapperIndex] = useState(BigNumber.from(0));
  const [secToNextEpoch, setSecToNextEpoch] = useState(0);
  const [nextEpochTimer, setNextEpochTimer] = useState({
    h: 0,
    m: 0,
    s: 0,
  });
  const [apy, setApy] = useState(0);
  const [epochRoi, setEpochRoi] = useState(0);
  const [estimatedRewards, setEstimatedRewards] = useState(BigNumber.from(0));
  const [currentEpochId, setCurrentEpochId] = useState(BigNumber.from(0));

  //Treasury
  const [treasuryWeth, setTreasuryWeth] = useState({
    balance: BigNumber.from(0),
  });
  const [treasurySqwn, setTreasurySqwn] = useState({
    balance: BigNumber.from(0),
  });
  const [treasuryLp, setTreasuryLp] = useState({ balance: BigNumber.from(0) });
  const [treasuryTotalUsd, setTreasuryTotalUsd] = useState(BigNumber.from(0));

  useEffect(() => {
    const privateProvider = new ethers.providers.WebSocketProvider(
      process.env.REACT_APP_ETHEREUM_KEY
    );
    const stakingContract = new ethers.Contract(
      voldermortStakingAddress,
      stakingAbi,
      privateProvider
    );
    const getCurrentEpochInfo = async () => {
      try {
        let info = await stakingContract.currentEpoch();
        setTotalToDistribute(info.totalToDistribute);
        setSecToNextEpoch(secondsUntilTimestamp(Number(info.endTime)));
      } catch (error) {
        console.log(error);
      }
    };
    let deadline = secToNextEpoch;
    if (secToNextEpoch !== 0 && deadline > 0) {
      const interval = setInterval(() => {
        setNextEpochTimer(secondsToDhms(deadline));
        if (deadline === 0) {
          getCurrentEpochInfo();
        }
        deadline = deadline - 1;
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [secToNextEpoch]);

  useEffect(() => {
    if (totalSupply.balance !== null && tokenPriceInUsd !== null) {
      setMarketCap(totalSupply.balance.mul(tokenPriceInUsd));
    }
  }, [totalSupply, tokenPriceInUsd]);

  useEffect(() => {
    if (totalEthLiquidity !== null && ethPrice !== null) {
      setTotalUsdLiquidity(totalEthLiquidity.mul(ethPrice).mul(2));
    }
  }, [totalEthLiquidity, ethPrice]);

  useEffect(() => {
    if (tokenPriceInEth !== null && ethPrice !== null) {
      setTokenPriceInUsd(tokenPriceInEth.mul(ethPrice));
    }
  }, [tokenPriceInEth, ethPrice]);

  useEffect(() => {
    if (
      treasuryWeth.balance !== null &&
      treasurySqwn.balance !== null &&
      tokenPriceInUsd !== null &&
      ethPrice !== null &&
      lpPriceInUsd !== null &&
      treasuryLp.balance !== null
    ) {
      //setTreasuryTotalUsd
      //treasuryWeth.balance.mul(ethPrice).mul(BigNumber.from(10).pow(39))).add
      //
      const value = treasuryWeth.balance
        .mul(ethPrice)
        .mul(BigNumber.from(10).pow(48))
        .add(treasurySqwn.balance.mul(tokenPriceInUsd))
        .add(treasuryLp.balance.mul(lpPriceInUsd));
      setTreasuryTotalUsd(value);
    }
  }, [
    treasuryWeth,
    treasurySqwn,
    treasuryLp,
    tokenPriceInUsd,
    ethPrice,
    lpPriceInUsd,
  ]);

  useEffect(() => {
    //LP Price
    if (
      totalEthLiquidity !== null &&
      ethPrice !== null &&
      lpTotalSupply !== null &&
      lpTotalSupply.gt(0)
    ) {
      const value = totalEthLiquidity
        .mul(2)
        .mul(ethPrice)
        .mul(BigNumber.from(10).pow(48))
        .div(lpTotalSupply);
      setLpPriceInUsd(value);
    }
  }, [totalEthLiquidity, ethPrice, lpTotalSupply]);

  /* useEffect(() => {
    if (
      wrapperCirculatingSupply.balance !== null &&
      epochDistribute !== null &&
      wrapperCirculatingSupply.balance.gt(0) &&
      epochDistribute.gt(0)
    ) {
      const apr = epochDistribute
        .mul(BigNumber.from(10).pow(9))
        .div(wrapperCirculatingSupply.balance);

      setEpochRoi(Number(ethers.utils.formatUnits(apr.mul(100), 9)));

      const newApy = (Number(ethers.utils.formatUnits(apr, 9)) + 1) ** 1095 - 1;
      setApy(newApy);
    }
  }, [wrapperCirculatingSupply, epochDistribute]);*/

  /*useEffect(() => {
    if (signer) {
      if (
        wrapperCirculatingSupply.balance !== null &&
        epochDistribute !== null &&
        wrapperCirculatingSupply.balance.gt(0) &&
        epochDistribute.gt(0) &&
        stakedAmount.balance !== null
        //&& stakedAmount.balance.gt(0)
      ) {
        const estimateRewards = stakedAmount.balance
          .mul(BigNumber.from(10).pow(9))
          .div(wrapperCirculatingSupply.balance)
          .mul(epochDistribute);
        setEstimatedRewards(estimateRewards);
      }
    }
  }, [signer, stakedAmount, wrapperCirculatingSupply, epochDistribute]);*/

  useEffect(() => {
    const privateProvider = new ethers.providers.WebSocketProvider(
      process.env.REACT_APP_ETHEREUM_KEY
    );

    const contract = new ethers.Contract(
      voldemortAddress,
      nonRebaseCoinAbi,
      privateProvider
    );

    const stakingContract = new ethers.Contract(
      voldermortStakingAddress,
      stakingAbi,
      privateProvider
    );

    const wethContract = new ethers.Contract(
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      sqwnAbi,
      privateProvider
    );

    const lpContract = new ethers.Contract(
      voldermortLpAddress,
      lpAbi,
      privateProvider
    );

    const getTotalSupply = async () => {
      try {
        const totalSupply = await contract.totalSupply();
        setTotalSupply((prev) => ({ ...prev, balance: totalSupply }));
        return totalSupply;
      } catch (error) {
        console.log(error);
      }
    };

    const getTreasuryWeth = async () => {
      try {
        const wethBalance = await wethContract.balanceOf(
          voldermortTreasuryAddress
        );
        setTreasuryWeth((prev) => ({ ...prev, balance: wethBalance }));
        return totalSupply;
      } catch (error) {
        console.log("WETH ERROR", error);
      }
    };

    const getTreasuryLp = async () => {
      try {
        const lpBalance = await lpContract.balanceOf(voldermortTreasuryAddress);
        setTreasuryLp((prev) => ({ ...prev, balance: lpBalance }));
      } catch (error) {
        console.log("LP ERROR", error);
      }
    };

    const getTreasurySqwn = async () => {
      try {
        const sqwnBalance = await contract.balanceOf(voldermortStakingAddress);
        setTreasurySqwn((prev) => ({ ...prev, balance: sqwnBalance }));
      } catch (error) {
        console.log("SQWN ERROR", error);
      }
    };

    const getPoolInfo = async () => {
      try {
        //get lp total supply
        let totalSupply = BigNumber.from(0);
        try {
          totalSupply = await lpContract.totalSupply();
        } catch (error) {
          console.log(error);
        }
        setLpTotalSupply(totalSupply);

        let balanceOfLp = BigNumber.from(0);
        if (
          voldermortLpAddress !== "0x0000000000000000000000000000000000000000"
        ) {
          /*  balanceOfLp = await lpContract.balanceOf(currentAccount);

            setbalanceOfLp(prev => ({
              ...prev,
              balance: balanceOfLp,
            })); */

          const resArr = await lpContract.getReserves();
          setTotalEthLiquidity(resArr[0]);
          setTokenPriceInEth(
            resArr[0].mul(BigNumber.from(10).pow(48)).div(resArr[1])
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getStakeMinAmount = async () => {
      try {
        let amount = await stakingContract.minStake();
        setStakeMinAmount(amount);
      } catch (error) {
        console.log(error);
      }
    };

    const getCurrentEpochInfo = async () => {
      try {
        let info = await stakingContract.currentEpoch();
        setTotalToDistribute(info.totalToDistribute);
        setSecToNextEpoch(secondsUntilTimestamp(Number(info.endTime)));
      } catch (error) {
        console.log(error);
      }
    };

    getTreasuryWeth();
    getTreasurySqwn();
    getTreasuryLp();
    getTotalSupply();
    getCurrentEpochInfo();
    getPoolInfo();
    getStakeMinAmount();

    if (signer) {
      setCurrentAccount(signer._address);

      const getStakedAmount = async () => {
        try {
          const details = await stakingContract.stakeDetails(signer._address);
          setStakedAmount((prev) => ({
            ...prev,
            balance: details.amountStaked,
          }));
          return totalStaked;
        } catch (error) {
          console.log(error);
        }
      };

      const getBalanceOfAddress = async () => {
        try {
          const balanceOf = await contract.balanceOf(signer._address);
          setBalanceOf((prev) => ({ ...prev, balance: balanceOf }));
          // return balanceOf;
        } catch (error) {
          console.log(error);
        }
      };

      const getAllowance = async () => {
        try {
          let allowance = await contract.allowance(
            signer._address,
            voldermortStakingAddress
          );
          setAllowance((prev) => ({ ...prev, balance: allowance }));

          return allowance;
        } catch (error) {
          console.log(error);
        }
      };

      const getUserUnclaimedRewards = async () => {
        try {
          let id = await stakingContract.epochId();
          setCurrentEpochId(id);

          try {
            let rewards = await stakingContract.claimAmountForEpoch(
              signer._address,
              id.sub(1)
            );
            setUnclaimedRewards(rewards);
          } catch (error) {
            console.log(error);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getStakedAmount();
      getAllowance();
      getBalanceOfAddress();
      getUserUnclaimedRewards();

      //EVENTS

      contract.on("Transfer", (sender, receiver, amount) => {
        sender = sender.toLowerCase();
        receiver = receiver.toLowerCase();

        if (sender === signer._address.toLowerCase()) {
          contract
            .balanceOf(signer._address)
            .then((res) => setBalanceOf((prev) => ({ ...prev, balance: res })))
            .catch((error) =>
              setBalanceOf((prev) => ({
                ...prev,
                balance: prev.balance.sub(amount),
              }))
            );

          if (receiver === voldermortStakingAddress) {
            setStakedAmount((prev) => ({
              ...prev,
              balance: prev.balance.add(amount),
            }));
            //Remove amount deposited from allowance
            setAllowance((prev) => ({
              ...prev,
              balance: prev.balance.sub(amount),
            }));
          }
        }

        if (receiver === signer._address.toLowerCase()) {
          contract
            .balanceOf(signer._address)
            .then((res) => setBalanceOf((prev) => ({ ...prev, balance: res })))
            .catch((error) =>
              setBalanceOf((prev) => ({
                ...prev,
                balance: prev.balance.add(amount),
              }))
            );
        }
      });

      contract.on(
        "Approval",
        (userAddress, contractAddressApproved, amount) => {
          if (userAddress.toLowerCase() === signer._address.toLowerCase()) {
            console.log("HELLO WORLD");
            if (
              contractAddressApproved.toLowerCase() === voldermortStakingAddress
            ) {
              setAllowance((prev) => ({
                ...prev,
                balance: amount,
              }));
            }
          }
        }
      );

      return () => {
        contract.removeAllListeners();
      };
    }
  }, [signer]);

  const handleStakeChange = (e) =>
    e.target.value
      ? setStakeAmount(ethers.utils.parseUnits(e.target.value.toString(), 9))
      : setStakeAmount(BigNumber.from(0));

  const onApprove = async () => {
    if (!isWrongChain) {
      if (signer) {
        const contract = new ethers.Contract(
          voldemortAddress,
          nonRebaseCoinAbi,
          signer
        );

        try {
          const response = await contract.approve(
            voldermortStakingAddress,
            ethers.utils.parseEther("99999999999999999999999999999999999999")
          );
          toast.promise(response.wait(1), {
            pending: "Transaction Pending...",
            success: `Approved`,
            error: "Transcation failed",
          });
        } catch (error) {
          if (error.data?.message?.includes("insufficient funds for gas")) {
            toast.error("Not enough eth for gas");
          } else {
            toast.error(`${error.code.toString().replace("_", " ")}`);
          }
        }
      } else {
        console.log("Signer Doesn't Exist ");
      }
    } else {
      toast.error("Wrong Chain");
    }
  };

  const onClaim = async () => {
    if (!isWrongChain) {
      if (signer) {
        if (unclaimedRewards.gt(0)) {
          const contract = new ethers.Contract(
            voldermortStakingAddress,
            stakingAbi,
            signer
          );

          try {
            const response = await contract.claim(currentAccount);
            toast.promise(response.wait(1), {
              pending: "Transaction Pending...",
              success: `Rewards Claimed`,
              error: "Transcation failed",
            });
          } catch (error) {
            if (error.data?.message?.includes("insufficient funds for gas")) {
              toast.error("Not enough eth for gas");
            } else {
              toast.error(`${error.code.toString().replace("_", " ")}`);
            }
          }
        } else {
          toast.error("No rewards to claim");
        }
      } else {
        toast.error("Signer Doesn't Exist ");
      }
    } else {
      toast.error("Wrong Chain");
    }
  };

  const onStake = async () => {
    if (!isWrongChain) {
      if (signer) {
        if (stakeAmount.gt(0)) {
          if (stakeAmount.lte(balanceOf.balance)) {
            if (stakeAmount.gte(stakeMinAmount)) {
              const contract = new ethers.Contract(
                voldermortStakingAddress,
                stakingAbi,
                signer
              );
              try {
                let response = await contract.stake(
                  currentAccount,
                  stakeAmount
                );
                toast.promise(response.wait(1), {
                  pending: "Transaction Pending...",
                  success: `${toFixedNoRoundParse(stakeAmount).toLocaleString(
                    "en-US"
                  )} staked`,
                  error: "Transcation failed",
                });
              } catch (error) {
                if (
                  error.data?.message?.includes("insufficient funds for gas")
                ) {
                  toast.error("Not enough eth for gas");
                } else {
                  toast.error(`${error.code.toString().replace("_", " ")}`);
                }
              }
            } else {
              toast.error(
                `Min amount is ${toFixedNoRoundParse(stakeMinAmount)}`
              );
            }
          } else {
            toast.error("Exceeded your balance");
          }
        } else {
          toast.error("Amount must me > 0");
        }
      } else {
        console.log("Signer Doesn't Exist");
      }
    } else {
      /*try {
            await onSwitchNetwork();
          } catch (error) {
            toast.error(`Action Rejected`);
          }*/
      toast.error("Wrong Chain");
    }
  };

  const onUnstake = async () => {
    if (!isWrongChain) {
      if (signer) {
        if (stakedAmount.balance.gt(0)) {
          const contract = new ethers.Contract(
            voldermortStakingAddress,
            stakingAbi,
            signer
          );
          try {
            /*const options = {
                    gasLimit: ethers.utils.parseUnits('641755', 0),
                    maxPriorityFeePerGas: ethers.utils.parseUnits('6', 8),
                  };*/
            let response = await contract.unstake(currentAccount);
            toast.promise(response.wait(1), {
              pending: "Transaction Pending...",
              success: `Unstaked`,
              error: "Transcation failed",
            });
          } catch (error) {
            if (error.data?.message?.includes("insufficient funds for gas")) {
              toast.error("Not enough eth for gas");
            } else {
              toast.error(`${error.code.toString().replace("_", " ")}`);
            }
          }
        } else {
          toast.error("No token staked");
        }
      } else {
        console.log("Signer Doesn't Exist");
      }
    } else {
      /*try {
            await onSwitchNetwork();
          } catch (error) {
            toast.error(`Action Rejected`);
          }*/
      toast.error("Wrong Chain");
    }
  };

  return (
    <div className="Staking">
      <div className="container">
        <div className="title-container">
          <h1>Staking</h1>
          <p>Stake $HELPME</p>
        </div>

        <div className="body-wrapper">
          <div className="body-container">
            {tokenPriceInUsd !== null && (
              <div className="single-box">
                <h3>$HELPME Price:</h3>
                <p>${toFixedNoRoundParseUnit(tokenPriceInUsd, 59, 10)}</p>
              </div>
            )}

            <div className="single-box">
              <h3>Market Cap:</h3>
              <p>${toFixedNoRoundParseUnit(marketCap, 68)}</p>
            </div>
            <div className="single-box">
              <h3>Total Liquidity:</h3>
              <p>${toFixedNoRoundParseUnit(totalUsdLiquidity, 20)}</p>
            </div>
            <div className="single-box">
              <h3>Next Epoch Rewards:</h3>
              <p>{toFixedNoRoundParseEther(totalToDistribute)} ETH</p>
            </div>
            <div className="single-box">
              <h3>Next Epoch Countdown:</h3>
              {nextEpochTimer && (
                <p>
                  {displayTimeTwoDigits(nextEpochTimer.h)}:
                  {displayTimeTwoDigits(nextEpochTimer.m)}:
                  {displayTimeTwoDigits(nextEpochTimer.s)}
                </p>
              )}
            </div>
            <div className="single-box">
              <a
                className="dex-link"
                href={`https://www.dextools.io/app/en/ether/pair-explorer/${voldermortLpAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Dex Link
              </a>
            </div>
            {/*<div className="body-container">
              <div className="single-box">
                <h3>APY:</h3>
                {apy && <p>{apy.toLocaleString("en-US").split(".")[0]}%</p>}
              </div>

              <div className="single-box">
                <h3>Single Epoch Roi:</h3>
                {epochRoi && <p>{epochRoi.toLocaleString("en-US")}%</p>}
              </div>
              </div>*/}
          </div>
          {signer && !isWrongChain ? (
            signer.provider._network.chainId === 1 ? (
              <div className="body-container">
                <div className="single-box">
                  <h3>Current Account:</h3>
                  <p>{currentAccount}</p>
                </div>

                {/* <div className="single-box">
                <h3>Next Epoch Estimated Rewards:</h3>
                {estimatedRewards && tokenPriceInUsd && (
                  <p>
                    {toFixedNoRoundParseEther(estimatedRewards)} QWN ($
                    {toFixedNoRoundParseUnit(
                      estimatedRewards.mul(tokenPriceInUsd),
                      77
                    )}
                    )
                  </p>
                )}
                    </div>*/}
                <div className="single-box">
                  <h3>Your ETH Balance:</h3>
                  <p>{toFixedNoRoundParseEther(addressEthBalance)} ETH</p>
                </div>

                <div className="single-box">
                  <h3>Your Balance:</h3>
                  <p>{toFixedNoRoundParse(balanceOf.balance)} $HELPME</p>
                </div>

                <div className="single-box">
                  <h3>Amount to Stake:</h3>
                  <input
                    onChange={(e) => {
                      handleStakeChange(e);
                      // setMaxValue(false);
                    }}
                    type="number"
                  />
                </div>
                <div className="single-box">
                  <h5>
                    Stake Min Amount: {toFixedNoRoundParse(stakeMinAmount)}{" "}
                    $HELPME
                  </h5>
                </div>
                <div className="mb10">
                  <div className="winXP-wrap">
                    <button
                      className="winXP-btn"
                      onClick={
                        allowance.balance.gt(0) &&
                        allowance.balance.gte(stakeAmount)
                          ? onStake
                          : onApprove
                      }
                    >
                      {allowance.balance.gt(0) &&
                      allowance.balance.gte(stakeAmount)
                        ? "Stake"
                        : "Approve $HELPME"}
                    </button>
                  </div>
                </div>
                <div className="single-box">
                  <h3>Staked balance:</h3>
                  <p>{toFixedNoRoundParse(stakedAmount.balance)} $HELPME</p>
                </div>
                <div className="mb10">
                  <div className="winXP-wrap">
                    <button className="winXP-btn" onClick={onUnstake}>
                      Unstake
                    </button>
                  </div>
                </div>
                <div className="single-box">
                  <h3>Unclaimed Rewards:</h3>
                  <p>{toFixedNoRoundParseEther(unclaimedRewards)} ETH</p>
                </div>
                <div>
                  <div className="winXP-wrap">
                    <button className="winXP-btn" onClick={onClaim}>
                      Claim
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="body-container">
                <ConnectButton isFooter={false} isWrongChain={true} />
              </div>
            )
          ) : (
            <div className="body-container">
              <ConnectButton isFooter={false} isWrongChain={isWrongChain} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
