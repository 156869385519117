import React, {
  useReducer,
  useRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled, { keyframes } from "styled-components";
import useMouse from "react-use/lib/useMouse";
import ga from "react-ga";

//react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  ADD_APP,
  DEL_APP,
  FOCUS_APP,
  MINIMIZE_APP,
  TOGGLE_MAXIMIZE_APP,
  FOCUS_ICON,
  SELECT_ICONS,
  FOCUS_DESKTOP,
  START_SELECT,
  END_SELECT,
  POWER_OFF,
  CANCEL_POWER_OFF,
} from "./constants/actions";
import { FOCUSING, POWER_STATE } from "./constants";
import { defaultIconState, defaultAppState, appSettings } from "./apps";
import Modal from "./Modal";
import Footer from "./Footer";
import Windows from "./Windows";
import Icons from "./Icons";
import { DashedBox } from "components";

//Connect Wallet
import { ethers, BigNumber } from "ethers";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import {
  configureChains,
  createConfig,
  WagmiConfig,
  useWalletClient,
  useChainId,
} from "wagmi";
import { getEthersSigner } from "./Web3Connection/Signer";
import { getEthersProvider } from "./Web3Connection/Provider";
import {
  watchWalletClient,
  watchNetwork,
  watchAccount,
  watchPublicClient,
  readContract,
  watchWebSocketPublicClient,
  switchNetwork,
} from "@wagmi/core";
import { mainnet, base } from "wagmi/chains";

//ABIS
import qwnAbi from "./abis/Qwn.json";
import sqwnAbi from "./abis/Sqwn.json";
import stakingAbi from "./abis/Staking.json";
import lpAbi from "./abis/Lp.json";
import treasuryAbi from "./abis/Treasury.json";
import qwaAbi from "./abis/Qwa.json";

//CONTRACTS
const qwnAddress = "0xb354b5da5ea39dadb1cea8140bf242eb24b1821a".toLowerCase();
const sqwnAddress = "0x03c793511B835E41769432Eb3a3eF4af02AB648c".toLowerCase();
const treasuryAddress =
  "0x9021d4F14513ebeF91DF1Db77c363D1A77b0B3b2".toLowerCase();
const stakingAddress =
  "0xA6897ccE09980A302822256F875A7dcB1eBb79C9".toLowerCase();
const distributorAddress =
  "0xC81BB9731C19372b31Bc2485DC62a5B75964678B".toLowerCase();
const qwaAddress = "0x33B8930a44610E2969aacF3C9864A5aEAD2CB40c".toLowerCase();
const lpAddress = "0x4eBdF501199410fC6cAa1Fa8C77Da9aAD46594Ce".toLowerCase();

/*
const Base = {
  id: 8453,
  name: 'Base Mainnet',
  network: 'Base Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://mainnet.base.org'] },
    default: { http: ['https://mainnet.base.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'BaseScan', url: 'https://basescan.org' },
    default: { name: 'BaseScan', url: 'https://basescan.org' },
  },
};*/

const toFixedNoRoundParse = (number, precision = 4) => {
  number = Number(ethers.utils.formatUnits(number, 9));
  const factor = Math.pow(10, precision);
  const result = Math.floor(number * factor) / factor;

  if (result === 0) {
    return "0.0";
  }
  return result;
};

const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24)) * 24;
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  return { h: d + h, m, s };
};

// 1. Get projectID at https://cloud.walletconnect.com
if (!process.env.REACT_APP_PUBLIC_PROJECT_ID) {
  throw new Error(
    "You need to provide REACT_APP_PUBLIC_PROJECT_ID env variable"
  );
}
const projectId = process.env.REACT_APP_PUBLIC_PROJECT_ID;

// 2. Configure wagmi client
const chains = [mainnet, base];

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ chains, projectId }),
  publicClient,
});

// 3. Configure modal ethereum client
const ethereumClient = new EthereumClient(wagmiConfig, chains);

/************************/

const initState = {
  apps: defaultAppState,
  nextAppID: defaultAppState.length,
  nextZIndex: defaultAppState.length,
  focusing: FOCUSING.WINDOW,
  icons: defaultIconState,
  selecting: false,
  powerState: POWER_STATE.START,
};
const reducer = (state, action = { type: "" }) => {
  ga.event({
    category: "XP interaction",
    action: action.type,
  });
  switch (action.type) {
    case ADD_APP:
      const app = state.apps.find(
        (_app) => _app.component === action.payload.component
      );
      if (action.payload.header.title == "Twitter") {
        window.open("https://twitter.com/QuantumWN", "_blank", "noreferrer");
        const apps = state.apps.map((app) =>
          app.component === action.payload.component
            ? { ...app, zIndex: state.nextZIndex, minimized: false }
            : app
        );
        return {
          ...state,
          apps,
          nextZIndex: state.nextZIndex + 1,
          focusing: FOCUSING.WINDOW,
        };
      }
      if (action.payload.header.title == "Telegram") {
        window.open(
          "https://t.me/quantumwealthnetwork",
          "_blank",
          "noreferrer"
        );
        const apps = state.apps.map((app) =>
          app.component === action.payload.component
            ? { ...app, zIndex: state.nextZIndex, minimized: false }
            : app
        );
        return {
          ...state,
          apps,
          nextZIndex: state.nextZIndex + 1,
          focusing: FOCUSING.WINDOW,
        };
      }
      if (action.payload.header.title == "Dextools") {
        window.open(
          "https://www.dextools.io/app/en/ether/pair-explorer/0x4ebdf501199410fc6caa1fa8c77da9aad46594ce",
          "_blank",
          "noreferrer"
        );
        const apps = state.apps.map((app) =>
          app.component === action.payload.component
            ? { ...app, zIndex: state.nextZIndex, minimized: false }
            : app
        );
        return {
          ...state,
          apps,
          nextZIndex: state.nextZIndex + 1,
          focusing: FOCUSING.WINDOW,
        };
      }
      if (action.payload.multiInstance || !app) {
        return {
          ...state,
          apps: [
            ...state.apps,
            {
              ...action.payload,
              id: state.nextAppID,
              zIndex: state.nextZIndex,
            },
          ],
          nextAppID: state.nextAppID + 1,
          nextZIndex: state.nextZIndex + 1,
          focusing: FOCUSING.WINDOW,
        };
      }
      const apps = state.apps.map((app) =>
        app.component === action.payload.component
          ? { ...app, zIndex: state.nextZIndex, minimized: false }
          : app
      );
      return {
        ...state,
        apps,
        nextZIndex: state.nextZIndex + 1,
        focusing: FOCUSING.WINDOW,
      };
    case DEL_APP:
      if (state.focusing !== FOCUSING.WINDOW) return state;
      return {
        ...state,
        apps: state.apps.filter((app) => app.id !== action.payload),
        focusing:
          state.apps.length > 1
            ? FOCUSING.WINDOW
            : state.icons.find((icon) => icon.isFocus)
            ? FOCUSING.ICON
            : FOCUSING.DESKTOP,
      };
    case FOCUS_APP: {
      const apps = state.apps.map((app) =>
        app.id === action.payload
          ? { ...app, zIndex: state.nextZIndex, minimized: false }
          : app
      );
      return {
        ...state,
        apps,
        nextZIndex: state.nextZIndex + 1,
        focusing: FOCUSING.WINDOW,
      };
    }
    case MINIMIZE_APP: {
      if (state.focusing !== FOCUSING.WINDOW) return state;
      const apps = state.apps.map((app) =>
        app.id === action.payload ? { ...app, minimized: true } : app
      );
      return {
        ...state,
        apps,
        focusing: FOCUSING.WINDOW,
      };
    }
    case TOGGLE_MAXIMIZE_APP: {
      if (state.focusing !== FOCUSING.WINDOW) return state;
      const apps = state.apps.map((app) =>
        app.id === action.payload ? { ...app, maximized: !app.maximized } : app
      );
      return {
        ...state,
        apps,
        focusing: FOCUSING.WINDOW,
      };
    }
    case FOCUS_ICON: {
      const icons = state.icons.map((icon) => ({
        ...icon,
        isFocus: icon.id === action.payload,
      }));
      return {
        ...state,
        focusing: FOCUSING.ICON,
        icons,
      };
    }
    case SELECT_ICONS: {
      const icons = state.icons.map((icon) => ({
        ...icon,
        isFocus: action.payload.includes(icon.id),
      }));
      return {
        ...state,
        icons,
        focusing: FOCUSING.ICON,
      };
    }
    case FOCUS_DESKTOP:
      return {
        ...state,
        focusing: FOCUSING.DESKTOP,
        icons: state.icons.map((icon) => ({
          ...icon,
          isFocus: false,
        })),
      };
    case START_SELECT:
      return {
        ...state,
        focusing: FOCUSING.DESKTOP,
        icons: state.icons.map((icon) => ({
          ...icon,
          isFocus: false,
        })),
        selecting: action.payload,
      };
    case END_SELECT:
      return {
        ...state,
        selecting: null,
      };
    case POWER_OFF:
      return {
        ...state,
        powerState: action.payload,
      };
    case CANCEL_POWER_OFF:
      return {
        ...state,
        powerState: POWER_STATE.START,
      };
    default:
      return state;
  }
};
function WinXP({
  ready,
  publicClient,
  signer,
  currentAccount,
  isWrongChain,
  isConnected,
  avatarColor,
}) {
  const [ethPrice, setEthPrice] = useState(null);
  const [tokenPriceInEth, setTokenPriceInEth] = useState(null);
  const [tokenPriceInUsd, setTokenPriceInUsd] = useState(null);
  const [lpPriceInUsd, setLpPriceInUsd] = useState(null);
  const [totalEthLiquidity, setTotalEthLiquidity] = useState(BigNumber.from(0));
  const [lpTotalSupply, setLpTotalSupply] = useState(BigNumber.from(0));
  const [balanceOfLp, setbalanceOfLp] = useState({
    balance: BigNumber.from(0),
  });
  const [addressEthBalance, setAddressEthBalance] = useState({
    balance: BigNumber.from(0),
  });
  const [totalSupply, setTotalSupply] = useState({ balance: null });
  const [balanceOf, setBalanceOf] = useState({ balance: BigNumber.from(0) });
  const [allowance, setAllowance] = useState({
    balance: BigNumber.from(0),
  });
  const [wrapperAllowance, setWrapperAllowance] = useState({
    balance: BigNumber.from(0),
  });
  const [wrapperCirculatingSupply, setWrapperCirculatingSupply] = useState({
    balance: BigNumber.from(0),
  });
  const [userInfoWrapper, setUserInfoWrapper] = useState({ amount: null });
  const [totalStaked, setTotalStaked] = useState({
    balance: null,
  });
  const [stakeAmount, setStakeAmount] = useState(BigNumber.from(0));
  const [unstakeAmount, setUnstakeAmount] = useState(BigNumber.from(0));

  const [stakedAmount, setStakedAmount] = useState({
    balance: BigNumber.from(0),
  });
  const [marketCap, setMarketCap] = useState(BigNumber.from(0));
  const [totalUsdLiquidity, setTotalUsdLiquidity] = useState(BigNumber.from(0));
  const [epochDistribute, setEpochDistribute] = useState(BigNumber.from(0));
  const [wrapperIndex, setWrapperIndex] = useState(BigNumber.from(0));
  const [secToNextEpoch, setSecToNextEpoch] = useState(0);
  const [nextEpochTimer, setNextEpochTimer] = useState({
    h: 0,
    m: 0,
    s: 0,
  });
  const [apy, setApy] = useState(0);
  const [epochRoi, setEpochRoi] = useState(0);
  const [estimatedRewards, setEstimatedRewards] = useState(BigNumber.from(0));

  //Treasury
  const [treasuryWeth, setTreasuryWeth] = useState({
    balance: BigNumber.from(0),
  });
  const [treasurySqwn, setTreasurySqwn] = useState({
    balance: BigNumber.from(0),
  });
  const [treasuryLp, setTreasuryLp] = useState({ balance: BigNumber.from(0) });
  const [treasuryTotalUsd, setTreasuryTotalUsd] = useState(BigNumber.from(0));

  useEffect(() => {
    const privateProvider = new ethers.providers.WebSocketProvider(
      process.env.REACT_APP_ETHEREUM_KEY
    );
    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingAbi,
      privateProvider
    );
    const getSecToNextEpoch = async () => {
      try {
        const sec = await stakingContract.secondsToNextEpoch();
        setSecToNextEpoch(Number(sec));
      } catch (error) {
        console.log(error);
      }
    };
    let deadline = secToNextEpoch;
    if (secToNextEpoch !== 0 && deadline > 0) {
      const interval = setInterval(() => {
        setNextEpochTimer(secondsToDhms(deadline));
        if (deadline === 0) {
          getSecToNextEpoch();
        }
        deadline = deadline - 1;
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [secToNextEpoch]);

  useEffect(() => {
    if (totalSupply.balance !== null && tokenPriceInUsd !== null) {
      setMarketCap(totalSupply.balance.mul(tokenPriceInUsd));
    }
  }, [totalSupply, tokenPriceInUsd]);

  useEffect(() => {
    if (totalEthLiquidity !== null && ethPrice !== null) {
      setTotalUsdLiquidity(totalEthLiquidity.mul(ethPrice).mul(2));
    }
  }, [totalEthLiquidity, ethPrice]);

  useEffect(() => {
    if (tokenPriceInEth !== null && ethPrice !== null) {
      setTokenPriceInUsd(tokenPriceInEth.mul(ethPrice));
    }
  }, [tokenPriceInEth, ethPrice]);

  useEffect(() => {
    if (
      treasuryWeth.balance !== null &&
      treasurySqwn.balance !== null &&
      tokenPriceInUsd !== null &&
      ethPrice !== null &&
      lpPriceInUsd !== null &&
      treasuryLp.balance !== null
    ) {
      //setTreasuryTotalUsd
      //treasuryWeth.balance.mul(ethPrice).mul(BigNumber.from(10).pow(39))).add
      //
      const value = treasuryWeth.balance
        .mul(ethPrice)
        .mul(BigNumber.from(10).pow(48))
        .add(treasurySqwn.balance.mul(tokenPriceInUsd))
        .add(treasuryLp.balance.mul(lpPriceInUsd));
      setTreasuryTotalUsd(value);
    }
  }, [
    treasuryWeth,
    treasurySqwn,
    treasuryLp,
    tokenPriceInUsd,
    ethPrice,
    lpPriceInUsd,
  ]);

  useEffect(() => {
    //LP Price
    if (
      totalEthLiquidity !== null &&
      ethPrice !== null &&
      lpTotalSupply !== null &&
      lpTotalSupply.gt(0)
    ) {
      const value = totalEthLiquidity
        .mul(2)
        .mul(ethPrice)
        .mul(BigNumber.from(10).pow(48))
        .div(lpTotalSupply);
      setLpPriceInUsd(value);
    }
  }, [totalEthLiquidity, ethPrice, lpTotalSupply]);

  useEffect(() => {
    if (
      wrapperCirculatingSupply.balance !== null &&
      epochDistribute !== null &&
      wrapperCirculatingSupply.balance.gt(0) &&
      epochDistribute.gt(0)
    ) {
      const apr = epochDistribute
        .mul(BigNumber.from(10).pow(9))
        .div(wrapperCirculatingSupply.balance);

      setEpochRoi(Number(ethers.utils.formatUnits(apr.mul(100), 9)));

      const newApy = (Number(ethers.utils.formatUnits(apr, 9)) + 1) ** 1095 - 1;
      setApy(newApy);
    }
  }, [wrapperCirculatingSupply, epochDistribute]);

  useEffect(() => {
    if (signer) {
      if (
        wrapperCirculatingSupply.balance !== null &&
        epochDistribute !== null &&
        wrapperCirculatingSupply.balance.gt(0) &&
        epochDistribute.gt(0) &&
        stakedAmount.balance !== null
        //&& stakedAmount.balance.gt(0)
      ) {
        const estimateRewards = stakedAmount.balance
          .mul(BigNumber.from(10).pow(9))
          .div(wrapperCirculatingSupply.balance)
          .mul(epochDistribute);
        setEstimatedRewards(estimateRewards);
      }
    }
  }, [signer, stakedAmount, wrapperCirculatingSupply, epochDistribute]);

  useEffect(() => {
    const privateProvider = new ethers.providers.WebSocketProvider(
      process.env.REACT_APP_ETHEREUM_KEY
    );

    const privateProviderBase = new ethers.providers.WebSocketProvider(
      process.env.REACT_APP_BASE_KEY
    );

    const contract = new ethers.Contract(qwnAddress, qwnAbi, privateProvider);
    const wrapperContract = new ethers.Contract(
      sqwnAddress,
      sqwnAbi,
      privateProvider
    );

    const stakingContract = new ethers.Contract(
      stakingAddress,
      stakingAbi,
      privateProvider
    );
    const treasuryContract = new ethers.Contract(
      treasuryAddress,
      treasuryAbi,
      privateProvider
    );

    const qwaContract = new ethers.Contract(
      qwaAddress,
      qwaAbi,
      privateProvider
    );

    const wethContract = new ethers.Contract(
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      sqwnAbi,
      privateProvider
    );

    const lpContract = new ethers.Contract(lpAddress, lpAbi, privateProvider);

    const fetchEthPrice = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        const data = await response.json();
        setEthPrice(BigNumber.from(data.ethereum.usd * 100));
      } catch (error) {
        console.error("Error fetching ETH price:", error);
      }
    };

    const getTotalSupply = async () => {
      try {
        const totalSupply = await contract.totalSupply();
        setTotalSupply((prev) => ({ ...prev, balance: totalSupply }));
        return totalSupply;
      } catch (error) {
        console.log(error);
      }
    };

    const getTreasuryWeth = async () => {
      try {
        const wethBalance = await wethContract.balanceOf(treasuryAddress);
        setTreasuryWeth((prev) => ({ ...prev, balance: wethBalance }));
        return totalSupply;
      } catch (error) {
        console.log("WETH ERROR", error);
      }
    };

    const getTreasuryLp = async () => {
      try {
        const lpBalance = await lpContract.balanceOf(treasuryAddress);
        setTreasuryLp((prev) => ({ ...prev, balance: lpBalance }));
      } catch (error) {
        console.log("LP ERROR", error);
      }
    };

    const getTreasurySqwn = async () => {
      try {
        const sqwnBalance = await wrapperContract.balanceOf(treasuryAddress);
        setTreasurySqwn((prev) => ({ ...prev, balance: sqwnBalance }));
      } catch (error) {
        console.log("SQWN ERROR", error);
      }
    };

    const getStakedAmount = async () => {
      try {
        const stakedAmount = await wrapperContract.balanceOf(currentAccount);
        setStakedAmount((prev) => ({
          ...prev,
          balance: stakedAmount,
        }));
        return totalStaked;
      } catch (error) {
        console.log(error);
      }
    };

    const getPoolInfo = async () => {
      try {
        //get lp total supply
        let totalSupply = BigNumber.from(0);
        try {
          totalSupply = await lpContract.totalSupply();
        } catch (error) {
          console.log(error);
        }
        setLpTotalSupply(totalSupply);

        let balanceOfLp = BigNumber.from(0);
        if (lpAddress !== "0x0000000000000000000000000000000000000000") {
          /*  balanceOfLp = await lpContract.balanceOf(currentAccount);

            setbalanceOfLp(prev => ({
              ...prev,
              balance: balanceOfLp,
            })); */

          const resArr = await lpContract.getReserves();
          setTotalEthLiquidity(resArr[1]);
          setTokenPriceInEth(
            resArr[1].mul(BigNumber.from(10).pow(48)).div(resArr[0])
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getBalanceOfAddress = async () => {
      try {
        const balanceOf = await contract.balanceOf(currentAccount);
        setBalanceOf((prev) => ({ ...prev, balance: balanceOf }));
        // return balanceOf;
      } catch (error) {
        console.log(error);
      }
    };

    const getEthBalanceOfAddress = async (provider) => {
      try {
        const response = await provider.getBalance(currentAccount);
        setAddressEthBalance((prev) => ({ ...prev, balance: response }));
      } catch (error) {
        console.log(error);
      }
    };

    const getAllowance = async () => {
      try {
        let allowance = await contract.allowance(
          currentAccount,
          stakingAddress
        );
        setAllowance((prev) => ({ ...prev, balance: allowance }));

        return allowance;
      } catch (error) {
        console.log(error);
      }
    };

    const getWrapperAllowance = async () => {
      try {
        let allowance = await wrapperContract.allowance(
          currentAccount,
          stakingAddress
        );
        setWrapperAllowance((prev) => ({ ...prev, balance: allowance }));

        return allowance;
      } catch (error) {
        console.log(error);
      }
    };

    const getWrapperCirculatingSupply = async () => {
      try {
        const supply = await wrapperContract.circulatingSupply();
        setWrapperCirculatingSupply((prev) => ({
          ...prev,
          balance: supply,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    const getEpochDistribute = async () => {
      //
      try {
        const distribute = await stakingContract.epoch();
        setEpochDistribute(distribute.distribute);
      } catch (error) {
        console.log(error);
      }
    };

    const getWrapperIndex = async () => {
      try {
        const index = await stakingContract.index();
        setWrapperIndex(index);
      } catch (error) {
        console.log(error);
      }
    };

    const getSecToNextEpoch = async () => {
      try {
        const sec = await stakingContract.secondsToNextEpoch();
        setSecToNextEpoch(Number(sec));
      } catch (error) {
        console.log(error);
      }
    };

    getWrapperIndex();
    getTreasuryWeth();
    getTreasurySqwn();
    getTreasuryLp();
    getTotalSupply();
    fetchEthPrice();
    getPoolInfo();
    getWrapperCirculatingSupply();
    getEpochDistribute();
    getSecToNextEpoch();

    if (signer) {
      if (signer.provider._network.chainId === 1) {
        getStakedAmount();
        getWrapperAllowance();
        getAllowance();
        getBalanceOfAddress();
        getEthBalanceOfAddress(privateProvider);

        //EVENTS

        contract.on("Transfer", (sender, receiver, amount) => {
          sender = sender.toLowerCase();
          receiver = receiver.toLowerCase();

          if (sender === currentAccount.toLowerCase()) {
            contract
              .balanceOf(currentAccount)
              .then((res) =>
                setBalanceOf((prev) => ({ ...prev, balance: res }))
              )
              .catch((error) =>
                setBalanceOf((prev) => ({
                  ...prev,
                  balance: prev.balance.sub(amount),
                }))
              );

            if (receiver === stakingAddress) {
              setStakedAmount((prev) => ({
                ...prev,
                balance: prev.balance.add(amount),
              }));
              //Remove amount deposited from allowance
              setAllowance((prev) => ({
                ...prev,
                balance: prev.balance.sub(amount),
              }));
            }
          }

          if (receiver === currentAccount.toLowerCase()) {
            contract
              .balanceOf(currentAccount)
              .then((res) =>
                setBalanceOf((prev) => ({ ...prev, balance: res }))
              )
              .catch((error) =>
                setBalanceOf((prev) => ({
                  ...prev,
                  balance: prev.balance.add(amount),
                }))
              );
          }
        });

        wrapperContract.on("Transfer", (sender, receiver, amount) => {
          sender = sender.toLowerCase();
          receiver = receiver.toLowerCase();

          if (sender === currentAccount.toLowerCase()) {
            wrapperContract
              .balanceOf(currentAccount)
              .then((res) =>
                setStakedAmount((prev) => ({ ...prev, balance: res }))
              )
              .catch((error) =>
                setStakedAmount((prev) => ({
                  ...prev,
                  balance: prev.balance.sub(amount),
                }))
              );

            if (receiver === stakingAddress) {
              setBalanceOf((prev) => ({
                ...prev,
                balance: prev.balance.add(amount),
              }));
              //Remove amount deposited from allowance
              setWrapperAllowance((prev) => ({
                ...prev,
                balance: prev.balance.sub(amount),
              }));
            }
          }

          if (receiver === currentAccount.toLowerCase()) {
            wrapperContract
              .balanceOf(currentAccount)
              .then((res) =>
                setStakedAmount((prev) => ({ ...prev, balance: res }))
              )
              .catch((error) =>
                setStakedAmount((prev) => ({
                  ...prev,
                  balance: prev.balance.add(amount),
                }))
              );
          }
        });

        contract.on(
          "Approval",
          (userAddress, contractAddressApproved, amount) => {
            if (userAddress.toLowerCase() === currentAccount.toLowerCase()) {
              console.log("HELLO WORLD");
              if (contractAddressApproved.toLowerCase() === stakingAddress) {
                setAllowance((prev) => ({
                  ...prev,
                  balance: amount,
                }));
              }
            }
          }
        );

        wrapperContract.on(
          "Approval",
          (userAddress, contractAddressApproved, amount) => {
            if (userAddress.toLowerCase() === currentAccount.toLowerCase()) {
              if (contractAddressApproved.toLowerCase() === stakingAddress) {
                setWrapperAllowance((prev) => ({
                  ...prev,
                  balance: amount,
                }));
              }
            }
          }
        );

        return () => {
          contract.removeAllListeners();
          wrapperContract.removeAllListeners();
          /* alchemyContract.removeAllListeners();
        alchemyFullContract.removeAllListeners();
        lpContract.removeAllListeners();*/
        };
      }else{
        getEthBalanceOfAddress(privateProviderBase);
      }
    }
  }, [signer]);

  const [state, dispatch] = useReducer(reducer, initState);
  const ref = useRef(null);
  const mouse = useMouse(ref);
  const focusedAppId = getFocusedAppId();
  const onFocusApp = useCallback((id) => {
    dispatch({ type: FOCUS_APP, payload: id });
  }, []);
  const onMaximizeWindow = useCallback(
    (id) => {
      if (focusedAppId === id) {
        dispatch({ type: TOGGLE_MAXIMIZE_APP, payload: id });
      }
    },
    [focusedAppId]
  );
  const onMinimizeWindow = useCallback(
    (id) => {
      if (focusedAppId === id) {
        dispatch({ type: MINIMIZE_APP, payload: id });
      }
    },
    [focusedAppId]
  );
  const onCloseApp = useCallback(
    (id) => {
      if (focusedAppId === id) {
        dispatch({ type: DEL_APP, payload: id });
      }
    },
    [focusedAppId]
  );
  function onMouseDownFooterApp(id) {
    if (focusedAppId === id) {
      dispatch({ type: MINIMIZE_APP, payload: id });
    } else {
      dispatch({ type: FOCUS_APP, payload: id });
    }
  }
  function onMouseDownIcon(id) {
    dispatch({ type: FOCUS_ICON, payload: id });
  }
  function onDoubleClickIcon(component) {
    const appSetting = Object.values(appSettings).find(
      (setting) => setting.component === component
    );
    dispatch({ type: ADD_APP, payload: appSetting });
  }
  function getFocusedAppId() {
    if (state.focusing !== FOCUSING.WINDOW) return -1;
    const focusedApp = [...state.apps]
      .sort((a, b) => b.zIndex - a.zIndex)
      .find((app) => !app.minimized);
    return focusedApp ? focusedApp.id : -1;
  }
  function onMouseDownFooter() {
    dispatch({ type: FOCUS_DESKTOP });
  }
  function onClickMenuItem(o) {
    if (o === "Internet")
      dispatch({ type: ADD_APP, payload: appSettings["Internet Explorer"] });
    else if (o === "Minesweeper")
      dispatch({ type: ADD_APP, payload: appSettings.Minesweeper });
    else if (o === "My Computer")
      dispatch({ type: ADD_APP, payload: appSettings["My Computer"] });
    else if (o === "Notepad")
      dispatch({ type: ADD_APP, payload: appSettings.Notepad });
    else if (o === "Winamp")
      dispatch({ type: ADD_APP, payload: appSettings.Winamp });
    else if (o === "Paint")
      dispatch({ type: ADD_APP, payload: appSettings.Paint });
    else if (o === "Log Off")
      dispatch({ type: POWER_OFF, payload: POWER_STATE.LOG_OFF });
    else if (o === "Turn Off Computer")
      dispatch({ type: POWER_OFF, payload: POWER_STATE.TURN_OFF });
    else
      dispatch({
        type: ADD_APP,
        payload: {
          ...appSettings.Error,
          injectProps: { message: "C:\\\nApplication not found" },
        },
      });
  }
  function onMouseDownDesktop(e) {
    if (e.target === e.currentTarget)
      dispatch({
        type: START_SELECT,
        payload: { x: mouse.docX, y: mouse.docY },
      });
  }
  function onMouseUpDesktop(e) {
    dispatch({ type: END_SELECT });
  }
  const onIconsSelected = useCallback(
    (iconIds) => {
      dispatch({ type: SELECT_ICONS, payload: iconIds });
    },
    [dispatch]
  );
  function onClickModalButton(text) {
    dispatch({ type: CANCEL_POWER_OFF });
    dispatch({
      type: ADD_APP,
      payload: appSettings.Error,
    });
  }
  function onModalClose() {
    dispatch({ type: CANCEL_POWER_OFF });
  }

  //WEB3 Functions

  const onApprove = async () => {
    if (!isWrongChain) {
      if (signer) {
        const contract = new ethers.Contract(qwnAddress, qwnAbi, signer);

        try {
          const response = await contract.approve(
            stakingAddress,
            ethers.utils.parseEther("99999999999999999999999999999999999999")
          );
          toast.promise(response.wait(1), {
            pending: "Transaction Pending...",
            success: `Approved`,
            error: "Transcation failed",
          });
        } catch (error) {
          if (error.data?.message?.includes("insufficient funds for gas")) {
            toast.error("Not enough eth for gas");
          } else {
            toast.error(`${error.code.toString().replace("_", " ")}`);
          }
        }
      } else {
        console.log("Signer Doesn't Exist ");
      }
    } else {
      /*try {
        //await onSwitchNetwork();
      } catch (error) {
        toast.error(`Action Rejected`);
      }*/
      toast.error("Wrong Chain");
    }
  };

  const onApproveWrapper = async () => {
    if (!isWrongChain) {
      if (signer) {
        const contract = new ethers.Contract(sqwnAddress, sqwnAbi, signer);

        try {
          const response = await contract.approve(
            stakingAddress,
            ethers.utils.parseEther("99999999999999999999999999999999999999")
          );
          toast.promise(response.wait(1), {
            pending: "Transaction Pending...",
            success: `Approved`,
            error: "Transcation failed",
          });
        } catch (error) {
          if (error.data?.message?.includes("insufficient funds for gas")) {
            toast.error("Not enough eth for gas");
          } else {
            toast.error(`${error.code.toString().replace("_", " ")}`);
          }
        }
      } else {
        console.log("Signer Doesn't Exist ");
      }
    } else {
      /*try {
        //await onSwitchNetwork();
      } catch (error) {
        toast.error(`Action Rejected`);
      }*/
      toast.error("Wrong Chain");
    }
  };

  const onStake = async () => {
    if (!isWrongChain) {
      if (signer) {
        if (stakeAmount.gt(0)) {
          if (stakeAmount.lte(balanceOf.balance)) {
            const contract = new ethers.Contract(
              stakingAddress,
              stakingAbi,
              signer
            );
            try {
              /*const options = {
                gasLimit: ethers.utils.parseUnits('641755', 0),
                maxPriorityFeePerGas: ethers.utils.parseUnits('6', 8),
              };*/
              let response = await contract.stake(currentAccount, stakeAmount);
              toast.promise(response.wait(1), {
                pending: "Transaction Pending...",
                success: `${toFixedNoRoundParse(stakeAmount).toLocaleString(
                  "en-US"
                )} $QWN staked`,
                error: "Transcation failed",
              });
            } catch (error) {
              if (error.data?.message?.includes("insufficient funds for gas")) {
                toast.error("Not enough eth for gas");
              } else {
                toast.error(`${error.code.toString().replace("_", " ")}`);
              }
            }
          } else {
            toast.error("Exceeded your balance");
          }
        } else {
          toast.error("Amount must me > 0");
        }
      } else {
        console.log("Signer Doesn't Exist");
      }
    } else {
      /*try {
        await onSwitchNetwork();
      } catch (error) {
        toast.error(`Action Rejected`);
      }*/
      toast.error("Wrong Chain");
    }
  };

  const onUnstake = async () => {
    if (!isWrongChain) {
      if (signer) {
        if (unstakeAmount.gt(0)) {
          if (unstakeAmount.lte(stakedAmount.balance)) {
            const contract = new ethers.Contract(
              stakingAddress,
              stakingAbi,
              signer
            );
            try {
              /*const options = {
                gasLimit: ethers.utils.parseUnits('641755', 0),
                maxPriorityFeePerGas: ethers.utils.parseUnits('6', 8),
              };*/
              let response = await contract.unstake(
                currentAccount,
                unstakeAmount,
                true
              );
              toast.promise(response.wait(1), {
                pending: "Transaction Pending...",
                success: `${toFixedNoRoundParse(unstakeAmount).toLocaleString(
                  "en-US"
                )} $QWN unstaked`,
                error: "Transcation failed",
              });
            } catch (error) {
              if (error.data?.message?.includes("insufficient funds for gas")) {
                toast.error("Not enough eth for gas");
              } else {
                toast.error(`${error.code.toString().replace("_", " ")}`);
              }
            }
          } else {
            toast.error("Exceeded your balance");
          }
        } else {
          toast.error("Amount must me > 0");
        }
      } else {
        console.log("Signer Doesn't Exist");
      }
    } else {
      /*try {
        await onSwitchNetwork();
      } catch (error) {
        toast.error(`Action Rejected`);
      }*/
      toast.error("Wrong Chain");
    }
  };

  return (
    <>
      {ready ? (
        <WagmiConfig config={wagmiConfig}>
          <Container
            ref={ref}
            onMouseUp={onMouseUpDesktop}
            onMouseDown={onMouseDownDesktop}
            state={state.powerState}
          >
            <ToastContainer
              position="top-center"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <Icons
              icons={state.icons}
              onMouseDown={onMouseDownIcon}
              onDoubleClick={onDoubleClickIcon}
              displayFocus={state.focusing === FOCUSING.ICON}
              appSettings={appSettings}
              mouse={mouse}
              selecting={state.selecting}
              setSelectedIcons={onIconsSelected}
            />
            <DashedBox startPos={state.selecting} mouse={mouse} />
            <Windows
              apps={state.apps}
              onMouseDown={onFocusApp}
              onClose={onCloseApp}
              onMinimize={onMinimizeWindow}
              onMaximize={onMaximizeWindow}
              focusedAppId={focusedAppId}
              signer={signer}
              balanceOf={balanceOf.balance}
              balanceOfLp={balanceOfLp.balance}
              allowance={allowance.balance}
              onApprove={() => onApprove()}
              stakeAmount={stakeAmount}
              setStakeAmount={setStakeAmount}
              onStake={() => onStake()}
              stakedAmount={stakedAmount.balance}
              wrapperAllowance={wrapperAllowance.balance}
              unstakeAmount={unstakeAmount}
              setUnstakeAmount={setUnstakeAmount}
              onUnstake={() => onUnstake()}
              onApproveWrapper={() => onApproveWrapper()}
              tokenPriceInUsd={tokenPriceInUsd}
              totalUsdLiquidity={totalUsdLiquidity}
              marketCap={marketCap}
              treasuryTotalUsd={treasuryTotalUsd}
              wrapperIndex={wrapperIndex}
              nextEpochTimer={nextEpochTimer}
              apy={apy}
              epochRoi={epochRoi}
              treasuryLp={treasuryLp.balance}
              treasurySqwn={treasurySqwn.balance}
              treasuryWeth={treasuryWeth.balance}
              estimatedRewards={estimatedRewards}
              isWrongChain={isWrongChain}
              addressEthBalance={addressEthBalance.balance}
              ethPrice={ethPrice}
            />
            <Footer
              currentAccount={currentAccount}
              apps={state.apps}
              onMouseDownApp={onMouseDownFooterApp}
              focusedAppId={focusedAppId}
              onMouseDown={onMouseDownFooter}
              onClickMenuItem={onClickMenuItem}
              avatarColor={avatarColor}
            />
            {state.powerState !== POWER_STATE.START && (
              <Modal
                onClose={onModalClose}
                onClickButton={onClickModalButton}
                mode={state.powerState}
                //onDisconnect={() => disconnect()}
              />
            )}
          </Container>
        </WagmiConfig>
      ) : null}

      <Web3Modal
        projectId={projectId}
        defaultChain={mainnet}
        ethereumClient={ethereumClient}
        themeMode="dark"
        themeVariables={{
          "--w3m-font-family": "'Orbitron', sans-serif",
          "--w3m-accent-color": "#0050FF",
          "--w3m-background-color": "#0050FF",
          "--w3m-button-border-radius": "3px",
          "--w3m-text-medium-regular-letter-spacing": "1px",
          "--w3m-text-medium-regular-size": "16px",
        }}
      />
    </>
  );
}

const powerOffAnimation = keyframes`
  0% {
    filter: brightness(1) grayscale(0);
  }
  30% {
    filter: brightness(1) grayscale(0);
  }
  100% {
    filter: brightness(0.6) grayscale(1);
  }
`;
const animation = {
  [POWER_STATE.START]: "",
  [POWER_STATE.TURN_OFF]: powerOffAnimation,
  [POWER_STATE.LOG_OFF]: powerOffAnimation,
};

const Container = styled.div`
  @import url("https://fonts.googleapis.com/css?family=Noto+Sans");
  font-family: Tahoma, "Noto Sans", sans-serif;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: url(https://i.ibb.co/FwcbvfJ/wallpaper.jpg) no-repeat center
    center fixed;
  background-size: cover;
  animation: ${({ state }) => animation[state]} 5s forwards;
  *:not(input):not(textarea) {
    user-select: none;
  }
`;

export default WinXP;
