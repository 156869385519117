import React, { useEffect, useState } from "react";
import "./staking.css";
import { ethers, BigNumber } from "ethers";
import ConnectButton from "../../Web3Connection/CustomButton";

//ABIS
import qwnAbi from "../../abis/Qwn.json";
import sqwnAbi from "../../abis/Sqwn.json";
import stakingAbi from "../../abis/Staking.json";
import lpAbi from "../../abis/Lp.json";
import treasuryAbi from "../../abis/Treasury.json";

//fixed amount of decimals without rounding it
const toFixedNoRoundParse = (number, precision = 4) => {
  number = Number(ethers.utils.formatUnits(number, 9));
  const factor = Math.pow(10, precision);
  const result = Math.floor(number * factor) / factor;

  if (result === 0) {
    return "0.0";
  }
  return result;
};

const toFixedNoRoundParseEther = (number, precision = 4) => {
  number = Number(ethers.utils.formatUnits(number, 18));
  const factor = Math.pow(10, precision);
  const result = Math.floor(number * factor) / factor;

  if (result === 0) {
    return "0.0";
  }
  return result;
};

const toFixedNoRoundParseUnit = (number, unit, precision = 18) => {
  number = ethers.utils.formatUnits(number, unit);
  number = number.split(".");
  const result = `${number[0]}.${number[1].substring(0, 2)}`;
  if (result === "0.00") {
    return "0.0";
  }
  return Number(result).toLocaleString("en-US");
};

const displayTimeTwoDigits = (number) => {
  number = number.toString();
  if (number.length < 2) {
    return "0" + number;
  }
  return number;
};

export default function Staking({
  onClose,
  signer,
  balanceOf,
  balanceOfLp,
  allowance,
  onApprove,
  stakeAmount,
  setStakeAmount,
  onStake,
  stakedAmount,
  wrapperAllowance,
  unstakeAmount,
  setUnstakeAmount,
  onUnstake,
  onApproveWrapper,
  wrapperIndex,
  nextEpochTimer,
  apy,
  epochRoi,
  estimatedRewards,
  tokenPriceInUsd,
  isWrongChain,
}) {
  const [currentAccount, setCurrentAccount] = useState(null);

  useEffect(() => {
    if (signer) {
      setCurrentAccount(signer._address);
    }
  }, [signer]);

  const handleStakeChange = (e) =>
    e.target.value
      ? setStakeAmount(ethers.utils.parseUnits(e.target.value.toString(), 9))
      : setStakeAmount(BigNumber.from(0));

  const handleUnstakeChange = (e) =>
    e.target.value
      ? setUnstakeAmount(ethers.utils.parseUnits(e.target.value.toString(), 9))
      : setUnstakeAmount(BigNumber.from(0));

  return (
    <div className="Staking">
      <div className="container">
        <div className="title-container">
          <h1>Staking</h1>
          <p>Stake $QWN for $sQWN</p>
        </div>

        <div className="body-wrapper">
          <div className="body-container">
            <div className="single-box">
              <h3>APY:</h3>
              {apy && <p>{apy.toLocaleString("en-US").split(".")[0]}%</p>}
            </div>

            <div className="single-box">
              <h3>Single Epoch Roi:</h3>
              {epochRoi && <p>{epochRoi.toLocaleString("en-US")}%</p>}
            </div>

            <div className="single-box">
              <h3>Staking Index:</h3>
              {wrapperIndex && (
                <p>{toFixedNoRoundParseUnit(wrapperIndex, 9)}</p>
              )}
            </div>

            <div className="single-box">
              <h3>Next Epoch Countdown:</h3>
              {nextEpochTimer && (
                <p>
                  {displayTimeTwoDigits(nextEpochTimer.h)}:
                  {displayTimeTwoDigits(nextEpochTimer.m)}:
                  {displayTimeTwoDigits(nextEpochTimer.s)}
                </p>
              )}
            </div>
          </div>
          {signer && !isWrongChain ? (
            signer.provider._network.chainId === 1 ? (
              <div className="body-container">
                <div className="single-box">
                  <h3>Current Account:</h3>
                  <p>{currentAccount}</p>
                </div>
                <div className="single-box">
                  <h3>Next Epoch Estimated Rewards:</h3>
                  {estimatedRewards && tokenPriceInUsd && (
                    <p>
                      {toFixedNoRoundParseEther(estimatedRewards)} QWN ($
                      {toFixedNoRoundParseUnit(
                        estimatedRewards.mul(tokenPriceInUsd),
                        77
                      )}
                      )
                    </p>
                  )}
                </div>
                <div className="single-box">
                  <h3>$QWN Balance:</h3>
                  <p>{toFixedNoRoundParse(balanceOf)}</p>
                </div>
                <div className="single-box">
                  <h3>Amount to Stake:</h3>
                  <input
                    onChange={(e) => {
                      handleStakeChange(e);
                      // setMaxValue(false);
                    }}
                    type="number"
                  />
                </div>
                <div className="mb10">
                  <div className="winXP-wrap">
                    <button
                      className="winXP-btn"
                      onClick={
                        allowance.gt(0) && allowance.gte(stakeAmount)
                          ? onStake
                          : onApprove
                      }
                    >
                      {allowance.gt(0) && allowance.gte(stakeAmount)
                        ? "Stake"
                        : "Approve $QWN"}
                    </button>
                  </div>
                </div>
                <div className="single-box">
                  <h3>$sQWN Balance:</h3>
                  <p>{toFixedNoRoundParse(stakedAmount)}</p>
                </div>
                <div className="single-box">
                  <h3>Amount to Unstake:</h3>
                  <input
                    onChange={(e) => {
                      handleUnstakeChange(e);
                      // setMaxValue(false);
                    }}
                    type="number"
                  />
                </div>

                <div className="mb10">
                  <div className="winXP-wrap">
                    <button
                      className="winXP-btn"
                      onClick={
                        wrapperAllowance.gt(0) &&
                        wrapperAllowance.gte(unstakeAmount)
                          ? onUnstake
                          : onApproveWrapper
                      }
                    >
                      {wrapperAllowance.gt(0) &&
                      wrapperAllowance.gte(unstakeAmount)
                        ? "Unstake"
                        : "Approve $SQWN"}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="body-container">
                <ConnectButton isFooter={false} isWrongChain={true} />
              </div>
            )
          ) : (
            <div className="body-container">
              <ConnectButton isFooter={false} isWrongChain={isWrongChain} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
